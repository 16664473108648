import React from "react";
import { SvgIcon } from "@mui/material";

export default function ProcessIcon(props) {
	return (
		<SvgIcon color={props.color}>
			<path
				fill="currentColor"
				d="M6.992 14.502a1 1 0 0 0-1 1v1.782a7.972 7.972 0 0 1-2-5.282a7.29 7.29 0 0 1 .052-.88a1 1 0 1 0-1.985-.24a9.173 9.173 0 0 0-.067 1.12a9.964 9.964 0 0 0 2.417 6.5H2.992a1 1 0 1 0 0 2h4a.982.982 0 0 0 .794-.422c.011-.015.026-.027.037-.043c.007-.01.007-.022.013-.032a.966.966 0 0 0 .106-.258a.952.952 0 0 0 .032-.156c.003-.03.018-.057.018-.089v-4a1 1 0 0 0-1-1Zm1.5-8.5H6.709a7.974 7.974 0 0 1 5.283-2a7.075 7.075 0 0 1 .88.053a1 1 0 0 0 .24-1.987a9.227 9.227 0 0 0-1.12-.066a9.964 9.964 0 0 0-6.5 2.417V3.002a1 1 0 0 0-2 0v4a.954.954 0 0 0 .039.195a.969.969 0 0 0 .141.346l.012.017a.973.973 0 0 0 .244.246c.011.008.017.02.028.028c.014.01.03.013.045.021a.958.958 0 0 0 .18.084a.988.988 0 0 0 .261.053c.018 0 .032.01.05.01h4a1 1 0 0 0 0-2Zm11.96 10.804a.967.967 0 0 0-.141-.345l-.011-.017a.973.973 0 0 0-.245-.246c-.011-.008-.016-.02-.028-.028c-.01-.007-.023-.007-.034-.014a1.154 1.154 0 0 0-.41-.136c-.032-.003-.059-.018-.091-.018h-4a1 1 0 0 0 0 2h1.782a7.973 7.973 0 0 1-5.282 2a7.074 7.074 0 0 1-.88-.054a1 1 0 0 0-.24 1.987a9.365 9.365 0 0 0 1.12.067a9.964 9.964 0 0 0 6.5-2.417v1.417a1 1 0 0 0 2 0v-4a.953.953 0 0 0-.04-.195Zm.54-11.304a1 1 0 0 0 0-2h-4a.952.952 0 0 0-.192.039l-.007.001a.968.968 0 0 0-.34.14l-.02.013a.974.974 0 0 0-.245.244c-.008.01-.02.016-.028.027c-.007.01-.007.023-.014.034a1.146 1.146 0 0 0-.136.413c-.003.03-.018.057-.018.089v4a1 1 0 1 0 2 0V6.719a7.975 7.975 0 0 1 2 5.283a7.289 7.289 0 0 1-.053.88a1.001 1.001 0 0 0 .872 1.113a1.03 1.03 0 0 0 .122.007a1 1 0 0 0 .991-.88a9.174 9.174 0 0 0 .068-1.12a9.964 9.964 0 0 0-2.417-6.5Z"
			/>
		</SvgIcon>
	);
}
