import React from "react";
// import { Icon } from "@iconify/react";
// import TestTube from "@iconify/icons-mdi/test-tube";

// export default function TestRunnerIcon(props) {
// 	return <Icon icon={TestTube} />;
// }

import { SvgIcon } from "@mui/material";

export default function TestRunnerIcon(props) {
	return (
		<SvgIcon fontSize="small" color={props.color}>
			<svg fill="currentColor" viewBox="0 0 867.156 867.156">
				<path d="M247.111,610.652h-98.61c-8.392,0-15.165,7.066-14.57,15.438c3.521,49.571,100.078,139.338,122.949,159.948 c3.072,2.768,4.824,6.705,4.824,10.84v8.817c0,33.944,27.517,61.461,61.461,61.461h220.81c33.948,0,61.47-27.521,61.47-61.47 v-8.808c0-4.135,1.752-8.072,4.824-10.84c22.871-20.61,119.427-110.377,122.948-159.948c0.596-8.371-6.178-15.438-14.569-15.438 h-98.603c-8.059,0-14.592-6.533-14.592-14.592v-66.103c0-4.135,1.752-8.072,4.824-10.84 c22.87-20.61,119.424-110.374,122.948-159.939c0.595-8.37-6.179-15.438-14.57-15.438h-98.61c-8.059,0-14.592-6.533-14.592-14.592 v-66.102c0-4.135,1.752-8.072,4.824-10.84c22.871-20.61,119.427-110.377,122.949-159.948c0.595-8.371-6.179-15.438-14.57-15.438 h-98.61c-8.059,0-14.592-6.533-14.592-14.592v-0.768C605.454,27.518,577.938,0,543.992,0H323.164 c-33.944,0-61.462,27.517-61.462,61.462v0.768c0,8.059-6.533,14.592-14.592,14.592H148.5c-8.392,0-15.165,7.067-14.57,15.438 c3.521,49.571,100.078,139.338,122.949,159.948c3.072,2.768,4.824,6.705,4.824,10.84v66.102c0,8.059-6.533,14.592-14.592,14.592 h-98.61c-8.392,0-15.165,7.067-14.57,15.438c3.524,49.566,100.078,139.329,122.948,159.939c3.072,2.768,4.824,6.705,4.824,10.84 v66.103C261.702,604.119,255.169,610.652,247.111,610.652z M434.103,74.082c53.213,0,96.35,43.137,96.35,96.35 s-43.137,96.35-96.35,96.35s-96.351-43.137-96.351-96.35S380.89,74.082,434.103,74.082z M434.103,337.75 c53.213,0,96.35,43.137,96.35,96.351c0,53.213-43.137,96.35-96.35,96.35s-96.351-43.137-96.351-96.351 C337.752,380.887,380.89,337.75,434.103,337.75z M434.103,601.408c53.213,0,96.35,43.137,96.35,96.35s-43.137,96.351-96.35,96.351 s-96.351-43.138-96.351-96.351S380.89,601.408,434.103,601.408z"></path>
			</svg>
		</SvgIcon>
	);
}
